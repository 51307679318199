<template>
  <transition name="slide">
    <div v-if="visible" class="alert" :class="type">
      <svg
        v-if="type === 'success'"
        v-svg
        symbol="success"
        size="0 0 21 21"
        role="info"
      ></svg>
      <svg v-else v-svg symbol="important" size="0 0 21 21" role="info"></svg>
      <div class="context">
        <span class="title">{{ title }}</span>
        <span class="text">{{ text }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    title: "",
    text: "",
    type: "",
    time: null
  }),
  mounted() {
    this.showAlert();
  },
  methods: {
    showAlert() {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, this.time || 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.slide-leave,
.slide-enter-to {
  transform: translateX(0%);
}
.alert {
  animation: slideright 0.3s;
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  top: 20px;
  right: 20px;
  padding: 12px;
  border-radius: 10px;
  min-width: 380px;
  background: #9b2727;
  filter: drop-shadow(4px 8px 15px rgba(155, 39, 39, 0.45));
  color: white;

  svg {
    fill: white;
  }
  .context {
    display: flex;
    flex-flow: column wrap;
    margin-left: 12px;
    span.title {
      font-weight: bold;
    }
  }
  &.success {
    background: #098b4d;
    color: white;
    filter: drop-shadow(4px 8px 15px rgba(9, 139, 77, 0.45));
  }
}

@media screen and (max-width: 768px) {
  .alert {
    left: 0;
    right: 0;
    min-width: auto;
    margin: 0 14px;
  }
}

@keyframes slideright {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>