import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from "@/util/i18n/get-browser-locale"
import { supportedLocalesInclude } from "./util/i18n/supported-locales"

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })
  if (supportedLocalesInclude(browserLocale)) {
    if(!localStorage.getItem('lang')){
      localStorage.setItem("lang", 'en')
    }
    return browserLocale
  } else {
    if(!localStorage.getItem('lang')) {
      localStorage.setItem("lang", process.env.VUE_APP_I18N_LOCALE)
      return process.env.VUE_APP_I18N_LOCALE
    } else {
      return localStorage.getItem('lang')
    }

  }

}
export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadLocaleMessages()
})
