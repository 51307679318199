import CampaignsService from "@/api/serviceCampaigns";

const state = () => ({
  price: null,
});

const getters = {
  getPrice: (state) => {
    return state.price;
  },
};

const mutations = {
  setPrices(state, payload) {
    state.price = payload;
  },
};

const actions = {
  getPrice({commit}, payload) {
    CampaignsService.getAutoRulesPrice(payload).then((result) => {
      if (result && result.status === 200) commit("setPrices", result.data);
    });
  },
  nullPrice({commit}) {
    commit("setPrices", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
