import serviceAdd from "@/api/serviceAdd";

const state = () => ({
  countries: [],
});

const getters = {
  getCountries: (state) => {
    return state.countries;
  },
};

const mutations = {
  setCountries(state, payload) {
    if (payload) {
      state.countries = payload.data;
      state.countries.unshift({
        name_ru: "Все страны",
        name_en: "All countries",
      });
    }
  },
};

const actions = {
  handleCountries({commit}) {
    serviceAdd.getCountries().then((result) => {
      commit("setCountries", result);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
