
function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export async function sendMetaEvent(url, name, amount, id) {

  const token = 'EAAEmQT8fp8gBO4MNipQ8ZBN0HjgWBKwyZCvma5nB7IuRJ2wlcmKYjncK8ExZCpP5BwJNCmtrv5pvxdcfsN3B5cMLzZCVmdDxpZCrLoYZA4EVBFnEvk1WNBAvN3scco220qJYzkW2Jfu1vh1O68bA0M6Iaal0HKOXZCZCIvq2afQPtM2sLbu89gaiV237ZCZBq264jlTgZDZD'
  const pixel = '252259800947791'
  
  const response = await fetch(`https://graph.facebook.com/v1.0/${pixel}/events?access_token=${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: [
        {
          event_name: name,
          event_id: id ? id: undefined,
          event_time: Math.floor(Date.now() / 1000), // Unix timestamp in seconds
          event_source_url: process.env.VUE_APP_API_DOMAIN + url,
          action_source: 'website',
          user_data: {
            client_user_agent: navigator.userAgent,
            fbp: getCookie('_fbp'),
          },
          custom_data: amount ? { currency: 'USD', value: amount } : undefined,
        },
      ],
    }),
  })
  await response.json()
}

