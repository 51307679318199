import * as Sentry from "@sentry/vue";

export async function captureSentryError(
  error,
  payload,
  status,
  data,
  url,
  headers,
  method
) {
  const payloadSize = new Blob([payload]).size ?? 0;

  let filteredPayload;

  if (payloadSize > 10 * Math.pow(10, 6) || payload?.status == "413") {
    filteredPayload = "Request Payload is too Large";
  } else {
    filteredPayload = payload;
  }

  Sentry.captureException(error, {
    extra: {
      requestPayload: filteredPayload, // payload запроса
      responseStatus: status, // статус ответа
      responseData: data, // данные ответа (если есть)
      requestUrl: url, // URL запроса
      requestHeaders: headers, // заголовки запроса
      requestMethod: method, // метод запроса
    },
  });
  await Sentry.flush(1000);
}
