export const helper = {
  copyCode(ref) {
    let testingCodeToCopy = document.getElementById(ref) || this.$refs[ref];
    testingCodeToCopy.setAttribute('type', 'text')
    testingCodeToCopy.select()

    try {
      const successful = document.execCommand('copy')
      let msg = successful ? 'successful' : 'unsuccessful'
      console.log(msg)
    } catch (err) {
      alert('Oops, unable to copy')
    }
  },
}
