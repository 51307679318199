import apiClient from './AxiosService';

export default {
    install: (Vue) => {
        Vue.prototype.$apiClient = apiClient;
        Vue.prototype.$setLangHeader = (lang) => {
            apiClient.defaults.headers.common['UserLanguage'] = lang ? lang : 'en';
        };
    },
};
